import {useCallback} from "react";

export default function AuthorizedFunctionNavigation(auth, roles)
{
	if (auth)
	{
		if (auth.permission.permission === null)
		{
			return true;
		}
		if (auth.permission.hasOwnProperty(roles))
		{
			if (auth.permission[roles] === true)
			{
				return true;
			}
		}
	}

	return false;
}
