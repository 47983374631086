import React from 'react'
import ContentLoader from "react-content-loader"
import {getColor} from "../../functions/colors";

const Loader = () =>
{
	return (
		<div>
			<div className="absolute top-0 left-0 w-full h-full z-50">
				{/*<ContentLoader
					speed={2}
					width={1920}
					height={1080}
					viewBox="0 0 1920 1080"
					backgroundColor={getColor('bg-grey-200')}
					foregroundColor={getColor('bg-grey-200')}
				>
					<rect x="0" y="65" rx="3" ry="3" width="255" height="1080"/>
					<rect x="270" y="95" rx="3" ry="3" width="50" height="13"/>
					<rect x="270" y="113" rx="3" ry="3" width="160" height="27"/>
					<rect x="1810" y="110" rx="3" ry="3" width="100" height="38"/>
					<rect x="289" y="196" rx="3" ry="3" width="44" height="10"/>
					<rect x="295" y="322" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="372" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="422" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="472" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="522" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="572" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="622" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="672" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="722" rx="3" ry="3" width="1592" height="40"/>
					<rect x="295" y="772" rx="3" ry="3" width="1592" height="40"/>
					<circle cx="1888" cy="32" r="17"/>
				</ContentLoader>*/}
			</div>
		</div>
	)
}
export default Loader