import React from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import Item from './item'
import Logo from './logo'
import '../../css/components/left-sidebar-1.css'
import {useTranslation} from "react-i18next";
import AuthorizedFunctionNavigation from "../utilities/AuthorizedFunctionNavigation";

const Sidebar = () =>
{
	const {t} = useTranslation();
	const {navigation} = useSelector(
		(state) => ({
			navigation: state.navigation,
		}),
		shallowEqual
	)
	const auth = useSelector(
		state => ({
			permission: state.permission,
		})
	);
	return (

		<div className="left-sidebar left-sidebar-1">
			<Logo/>
			{auth && navigation && navigation.map((menu, i) => (
				<div key={i}>
					<div className="left-sidebar-title">
						<span>{t(`sidebar.${menu.title}`)}</span>
					</div>
					<ul>
						{menu.items.map((l0, a) =>
						{
							if (l0.items.length > 0)
							{
								return <li key={a} className="l0">
									<Item {...l0} />
									<ul>
										{l0.items.map((l1, b) =>
										{
											if (AuthorizedFunctionNavigation(auth,l1.roles))
											{
												return <li key={b} className="l1">
													<Item {...l1} />
													<ul>
														{l1.items.map((l2, c) =>
														{
															if (AuthorizedFunctionNavigation(auth,l2.roles))
															{
																return <li key={c} className="l2">
																	<Item {...l2} />
																	<ul>
																		{l2.items.map((l3, d) =>
																		{
																			if (AuthorizedFunctionNavigation(auth,l3.roles))
																			{
																				return <li key={d} className="l3">
																					<Item {...l3} />
																					<ul>
																						{l3.items.map((l4, e) =>
																						{
																							if (AuthorizedFunctionNavigation(auth,l4.roles))
																							{
																								return <li key={e}
																										   className="l4">
																									<Item {...l4} />
																								</li>
																							}
																							return ''
																						})}
																					</ul>
																				</li>
																			}
																			return ''
																		})}
																	</ul>
																</li>
															}
															return ''
														})}
													</ul>
												</li>
											}
											return ''
										})}
									</ul>
								</li>
							}
							else if (AuthorizedFunctionNavigation(auth,l0.roles))
							{
								return <li key={a} className="l0">
									<Item {...l0} />
									<ul>
										{l0.items.map((l1, b) =>
										{
											if (AuthorizedFunctionNavigation(auth,l1.roles))
											{
												return <li key={b} className="l1">
													<Item {...l1} />
													<ul>
														{l1.items.map((l2, c) =>
														{
															if (AuthorizedFunctionNavigation(auth,l2.roles))
															{
																return <li key={c} className="l2">
																	<Item {...l2} />
																	<ul>
																		{l2.items.map((l3, d) =>
																		{
																			if (AuthorizedFunctionNavigation(auth,l3.roles))
																			{
																				return <li key={d} className="l3">
																					<Item {...l3} />
																					<ul>
																						{l3.items.map((l4, e) =>
																						{
																							if (AuthorizedFunctionNavigation(auth,
																								l4.roles))
																							{
																								return <li key={e}
																										   className="l4">
																									<Item {...l4} />
																								</li>
																							}
																							return ''
																						})}
																					</ul>
																				</li>
																			}
																			return ''
																		})}
																	</ul>
																</li>
															}
															return ''
														})}
													</ul>
												</li>
											}
											return ''
										})}
									</ul>
								</li>
							}
							return ''
						})}
					</ul>
				</div>
			))}
		</div>
	)
}

export default Sidebar
